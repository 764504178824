import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { authToken, loading } = useAuth();

  // Exibe um indicador de carregamento enquanto verifica o estado de autenticação
  if (loading) {
    return <div>Carregando...</div>;
  }

  // Se o usuário não estiver autenticado, redireciona para a página de login
  return authToken ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;