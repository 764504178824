import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, CircularProgress, InputAdornment } from '@mui/material';
import { TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik'; // Certifique-se de importar 'Form' do 'formik'
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import api from '../../../api';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import CarIcon from '@mui/icons-material/DirectionsCar';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import RoomIcon from '@mui/icons-material/Room';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Nome é obrigatório'),
  last_name: Yup.string().required('Sobrenome é obrigatório'),
  cpf: Yup.string().required('CPF é obrigatório'),
  phone_number: Yup.string().required('Telefone é obrigatório'),
  ticket_code: Yup.string().required('Número do auto de infração é obrigatório'),
  plate: Yup.string().required('Placa do carro é obrigatória'),
  renavam: Yup.string().required('Renavam é obrigatório'),
  cep: Yup.string().required('CEP é obrigatório'),
  street_name: Yup.string().required('Nome da rua é obrigatório'),
  street_number: Yup.string().required('Número da rua é obrigatório'),
  neighborhood: Yup.string().required('Bairro é obrigatório'),
  city: Yup.string().required('Cidade é obrigatória'),
  state: Yup.string().required('Estado é obrigatório'),
  occupation: Yup.string().required('Ocupação é obrigatória'),
  license_number: Yup.string().required('Número da habilitação é obrigatório'),
  license_issuer: Yup.string().required('Emissor da habilitação é obrigatório'),
  rg: Yup.string().required('RG é obrigatório'),
  manufacturer: Yup.string().required('Fabricante é obrigatório'),
  model: Yup.string().required('Modelo é obrigatório'),
  year: Yup.string().required('Ano é obrigatório'),
  authority: Yup.string().required('Autoridade específica é obrigatória'),
  gender: Yup.string().required('Gênero é obrigatório'),
  nationality: Yup.string().required('Nacionalidade é obrigatório'),
  marital_status: Yup.string().required('Estado Civil é obrigatório'),
  address_complement: Yup.string(), // Complemento é opcional
});

const FillPersonalAndVehicleInfo = () => {
  const { identifier } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Busca os dados do evento
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await api.get(`/events/${identifier}`);
        setEvent(response.data.event);
        setLoading(false);

        if (response.data.event.status !== 'payment_confirmed') {
          await api.patch(`/events/${identifier}`, {
            event: { status: 'payment_confirmed' },
          });
        }
        
      } catch (error) {
        console.error('Erro ao buscar os dados do evento:', error);
        setLoading(false);
      }
    };

    fetchEventData();
  }, [identifier]);

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16524803345/8lxxCPLO0toZEJH60cc9',
      });
    }
  }, []);

  // Função para buscar o endereço via ViaCEP
  const buscarEndereco = async (cep, setFieldValue) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (!data.erro) {
        setFieldValue('street_name', data.logradouro);
        setFieldValue('neighborhood', data.bairro);
        setFieldValue('city', data.localidade);
        setFieldValue('state', data.uf);
      }
    } catch (error) {
      console.error('Erro ao buscar o endereço:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log('handleSubmit chamado com valores:', values); // Log para depuração
    setSubmitting(true);
    try {
      const response = await api.patch(`/events/${identifier}/update_event_info`, {
        event: {
          ...event,
          first_name: values.first_name,
          last_name: values.last_name,
          cpf: values.cpf,
          phone_number: values.phone_number,
          ticket_code: values.ticket_code,
          authority: values.authority,
          nationality: values.nationality,
          gender: values.gender,
          marital_status: values.marital_status,
          occupation: values.occupation,
          license_number: values.license_number,
          license_issuer: values.license_issuer,
          rg: values.rg,
          cep: values.cep,
          street_name: values.street_name,
          street_number: values.street_number,
          neighborhood: values.neighborhood,
          address_complement: values.address_complement,
          city: values.city,
          state: values.state,
          plate: values.plate,
          renavam: values.renavam,
          manufacturer: values.manufacturer,
          model: values.model,
          year: values.year,
        },
      });
  
      console.log('Resposta da API:', response);
  
      if (response.status === 200 || response.status === 204) {
        navigate(`/download-petition/${identifier}`);
      } else {
        console.error('Erro na resposta da API:', response);
      }
    } catch (error) {
      console.error('Erro ao enviar os dados', error);
    }
    setSubmitting(false);
  };  

  return (
    <Box sx={{ p: 4 }}>
      <Formik
        initialValues={{
          first_name: event?.first_name || '',
          last_name: event?.last_name || '',
          cpf: event?.cpf || '',
          phone_number: event?.phone_number || '',
          ticket_code: event?.ticket_code || '',
          authority: event?.authority || '',
          nationality: event?.nationality || 'Brasileiro',
          gender: event?.gender || '',
          marital_status: event?.marital_status || '',
          occupation: event?.occupation || '',
          license_number: event?.license_number || '',
          license_issuer: event?.license_issuer || '',
          rg: event?.rg || '',
          cep: event?.cep || '',
          street_name: event?.street_name || '',
          street_number: event?.street_number || '',
          neighborhood: event?.neighborhood || '',
          address_complement: event?.address_complement || '',
          city: event?.city || '',
          state: event?.state || '',
          plate: event?.plate || '',
          renavam: event?.renavam || '',
          manufacturer: event?.manufacturer || '',
          model: event?.model || '',
          year: event?.year || '',        
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit} // 'onSubmit' está no Formik
      >
        {({ values, errors, touched, handleChange, isSubmitting, setFieldValue }) => {
          console.log('Erros de validação:', errors); // Log para depuração
          return (
            <Form>
              <Typography variant="h4" gutterBottom>
                Informações para Petição
              </Typography>

              <Grid container spacing={2}>
                {/* Subsessão 1: Informações Pessoais */}
                <Grid item xs={12}>
                  <Typography variant="h6">Informações Pessoais</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                <TextField
                  label="Nome"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  fullWidth
                  error={touched.first_name && Boolean(errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Sobrenome"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  fullWidth
                  error={touched.last_name && Boolean(errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputMask
                  mask="999.999.999-99"
                  value={values.cpf}
                  onChange={handleChange}
                >
                  {() => (
                    <TextField
                      label="CPF"
                      name="cpf"
                      fullWidth
                      error={touched.cpf && Boolean(errors.cpf)}
                      helperText={touched.cpf && errors.cpf}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputMask
                  mask="(99) 99999-9999"
                  value={values.phone_number}
                  onChange={handleChange}
                >
                  {() => (
                    <TextField
                      label="Telefone"
                      name="phone_number"
                      fullWidth
                      error={touched.phone_number && Boolean(errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Ocupação"
                  name="occupation"
                  value={values.occupation}
                  onChange={handleChange}
                  fullWidth
                  error={touched.occupation && Boolean(errors.occupation)}
                  helperText={touched.occupation && errors.occupation}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Número da Habilitação"
                  name="license_number"
                  value={values.license_number}
                  onChange={handleChange}
                  fullWidth
                  error={touched.license_number && Boolean(errors.license_number)}
                  helperText={touched.license_number && errors.license_number}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Emissor da Habilitação"
                  name="license_issuer"
                  value={values.license_issuer}
                  onChange={handleChange}
                  fullWidth
                  error={touched.license_issuer && Boolean(errors.license_issuer)}
                  helperText={touched.license_issuer && errors.license_issuer}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="RG"
                  name="rg"
                  value={values.rg}
                  onChange={handleChange}
                  fullWidth
                  error={touched.rg && Boolean(errors.rg)}
                  helperText={touched.rg && errors.rg}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth error={touched.gender && Boolean(errors.gender)}>
                  <InputLabel>Gênero</InputLabel>
                  <Select
                    label="Gênero"
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                  >
                    <MenuItem value="Masculino">Masculino</MenuItem>
                    <MenuItem value="Feminino">Feminino</MenuItem>
                  </Select>
                  {touched.gender && errors.gender && (
                    <FormHelperText>{errors.gender}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth error={touched.marital_status && Boolean(errors.marital_status)}>
                  <InputLabel>Estado Civil</InputLabel>
                  <Select
                    label="Estado Civil"
                    name="marital_status"
                    value={values.marital_status}
                    onChange={handleChange}
                  >
                    <MenuItem value="Solteiro">Solteiro</MenuItem>
                    <MenuItem value="Casado">Casado</MenuItem>
                    <MenuItem value="Divorciado">Divorciado</MenuItem>
                    <MenuItem value="Viúvo">Viuvo</MenuItem>
                    <MenuItem value="Separado Judicialmente">Separado Judicialmente</MenuItem>
                  </Select>
                  {touched.marital_status && errors.marital_status && (
                    <FormHelperText>{errors.marital_status}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nacionalidade"
                  name="nationality"
                  value={values.nationality}
                  onChange={handleChange}
                  fullWidth
                  error={touched.nationality && Boolean(errors.nationality)}
                  helperText={touched.nationality && errors.nationality}
                />
              </Grid>

              {/* Subsessão 2: Informações de da Multa */}
              <Grid item xs={12}>
                <Typography variant="h6">Dados da Multa</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código da Multa"
                  name="ticket_code"
                  value={values.ticket_code}
                  onChange={handleChange}
                  fullWidth
                  error={touched.ticket_code && Boolean(errors.ticket_code)} // Corrigido para 'ticket_code'
                  helperText={touched.ticket_code && errors.ticket_code} // Corrigido o typo
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth error={touched.authority && Boolean(errors.authority)}>
                  <InputLabel>Qual autoridade/instância?</InputLabel>
                  <Select
                    label="Qual autoridade/instância?"
                    name="authority"
                    value={values.authority}
                    onChange={handleChange}
                  >
                    <MenuItem value="entity">Entidade Autuadora - 1ª Instância</MenuItem>
                    <MenuItem value="jari">JARI - 2ª Instância</MenuItem>
                    <MenuItem value="cetran">CETRAN - 3ª Instância</MenuItem>
                  </Select>
                  {touched.authority && errors.authority && (
                    <Typography variant="caption" color="error">
                      {errors.authority}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              {/* Subsessão 2: Informações de Endereço */}
              <Grid item xs={12}>
                <Typography variant="h6">Informações de Endereço</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputMask
                  mask="99999-999"
                  value={values.cep}
                  onBlur={() => buscarEndereco(values.cep, setFieldValue)}
                  onChange={handleChange}
                >
                  {() => (
                    <TextField
                      label="CEP"
                      name="cep"
                      fullWidth
                      error={touched.cep && Boolean(errors.cep)}
                      helperText={touched.cep && errors.cep}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RoomIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome da Rua"
                  name="street_name"
                  value={values.street_name}
                  onChange={handleChange}
                  fullWidth
                  error={touched.street_name && Boolean(errors.street_name)}
                  helperText={touched.street_name && errors.street_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Número"
                  name="street_number"
                  value={values.street_number}
                  onChange={handleChange}
                  fullWidth
                  error={touched.street_number && Boolean(errors.street_number)}
                  helperText={touched.street_number && errors.street_number}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Bairro"
                  name="neighborhood"
                  value={values.neighborhood}
                  onChange={handleChange}
                  fullWidth
                  error={touched.neighborhood && Boolean(errors.neighborhood)}
                  helperText={touched.neighborhood && errors.neighborhood}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationCityIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Complemento"
                  name="address_complement"
                  value={values.address_complement}
                  onChange={handleChange}
                  fullWidth
                  error={touched.address_complement && Boolean(errors.address_complement)}
                  helperText={touched.address_complement && errors.address_complement}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cidade"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  fullWidth
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Estado"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  fullWidth
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                />
              </Grid>

              {/* Subsessão 3: Informações do Veículo */}
              <Grid item xs={12}>
                <Typography variant="h6">Informações do Veículo</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Placa"
                  name="plate"
                  value={values.plate}
                  onChange={handleChange}
                  fullWidth
                  error={touched.plate && Boolean(errors.plate)}
                  helperText={touched.plate && errors.plate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CarIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Renavam"
                  name="renavam"
                  value={values.renavam}
                  onChange={handleChange}
                  fullWidth
                  error={touched.renavam && Boolean(errors.renavam)}
                  helperText={touched.renavam && errors.renavam}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Fabricante"
                  name="manufacturer"
                  value={values.manufacturer}
                  onChange={handleChange}
                  fullWidth
                  error={touched.manufacturer && Boolean(errors.manufacturer)}
                  helperText={touched.manufacturer && errors.manufacturer}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Modelo"
                  name="model"
                  value={values.model}
                  onChange={handleChange}
                  fullWidth
                  error={touched.model && Boolean(errors.model)}
                  helperText={touched.model && errors.model}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Ano"
                  name="year"
                  value={values.year}
                  onChange={handleChange}
                  fullWidth
                  error={touched.year && Boolean(errors.year)}
                  helperText={touched.year && errors.year}
                />
              </Grid>

                {/* Botões */}
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={24} /> : 'Atualizar Informações'}
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={() => navigate('/')} sx={{ ml: 2 }}>
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default FillPersonalAndVehicleInfo;