import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, CircularProgress, Alert, Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useEvent } from '../../../context/EventContext';
import { useAuth } from '../../../context/AuthContext';

const LoginOrSignUp = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const { event, updateEvent, fetchEvent, associateEventWithUser, loading: eventLoading } = useEvent(); // Usa o contexto para manipular o evento
  const { login, signUp, loading: authLoading } = useAuth(); // Usa o contexto de autenticação
  const [userData, setUserData] = useState({
    email: '',
    password: ''
  });
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Atualiza o status para 'arguments_reviewed' ao carregar o componente, apenas se o status for diferente
  useEffect(() => {
    const updateEventStatus = async () => {
      const fetchedEvent = await fetchEvent(identifier);

      // Verifique se o evento foi carregado e se o status é diferente de 'arguments_reviewed'
      if (fetchedEvent && fetchedEvent.status !== 'arguments_reviewed') {
        await updateEvent(identifier, { status: 'arguments_reviewed' });
      }
    };

    // Verifique se o evento já foi carregado para evitar loops desnecessários
    if (!eventLoading && event?.status !== 'arguments_reviewed') {
      updateEventStatus(); // Chame a função aqui apenas se o status for diferente
    }
  }, [identifier, fetchEvent, updateEvent, eventLoading, event?.status]); // Dependências corretas

  // Função para lidar com mudanças no formulário de cadastro
  const handleChangeSignUp = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    });
  };

  // Função para lidar com mudanças no formulário de login
  const handleChangeLogin = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value
    });
  };

  // Função para submeter o formulário de criação de conta
  const handleSignUp = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await signUp(userData.email, userData.password);
  
      // Verifique se o token está presente na resposta
      if (response.token) {
        await associateEventWithUser(identifier, response.token);
        navigate(`/complete-payment/${identifier}`);
      } else {
        throw new Error('Token não encontrado na resposta');
      }
    } catch (error) {
      console.error('Erro ao criar a conta:', error);
      setErrorMessage('Erro ao criar a conta. Verifique os dados e tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  // Função para submeter o formulário de login
  const handleLogin = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      console.log('Tentando login com:', loginData.email);
  
      // Tentativa de login
      const response = await login(loginData.email, loginData.password);
  
      // Verifique o conteúdo da resposta completa
      console.log('Resposta da API de login:', response); // Verifique se o token está presente na resposta
  
      if (response.token) { // Verifique se a resposta contém o token
        await associateEventWithUser(identifier, response.token);
        navigate(`/complete-payment/${identifier}`);
      } else {
        throw new Error('Token não encontrado na resposta');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setErrorMessage('Erro ao fazer login. Verifique os dados e tente novamente.');
    } finally {
      setLoading(false);
    }
  };  

  return (
    <Box sx={{ p: 4, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Entre em contato ou acesse sua conta
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6}>
          {/* Formulário de Sign Up */}
          <Typography variant="h5" gutterBottom>
            Crie sua conta
          </Typography>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <TextField
            label="E-mail"
            name="email"
            variant="outlined"
            fullWidth
            type="email"
            value={userData.email}
            onChange={handleChangeSignUp}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Senha"
            name="password"
            variant="outlined"
            fullWidth
            type="password"
            value={userData.password}
            onChange={handleChangeSignUp}
            sx={{ mb: 2 }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={handleSignUp}
            disabled={loading || authLoading} // Desabilitar durante o carregamento
            fullWidth
          >
            {loading || authLoading ? <CircularProgress size={24} /> : 'Criar conta'}
          </Button>
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* Formulário de Login */}
          <Typography variant="h5" gutterBottom>
            Faça Login
          </Typography>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <TextField
            label="E-mail"
            name="email"
            variant="outlined"
            fullWidth
            type="email"
            value={loginData.email}
            onChange={handleChangeLogin}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Senha"
            name="password"
            variant="outlined"
            fullWidth
            type="password"
            value={loginData.password}
            onChange={handleChangeLogin}
            sx={{ mb: 2 }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            disabled={loading || authLoading} // Desabilitar durante o carregamento
            fullWidth
          >
            {loading || authLoading ? <CircularProgress size={24} /> : 'Fazer Login'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginOrSignUp;
