import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress, Grid, Paper } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Send, Balance, Gavel, VerifiedUser, ReportProblem } from '@mui/icons-material';
import { useEvent } from '../../../context/EventContext';

const AnalyzeDefenseArguments = () => {
  const { identifier } = useParams();
  const { event, fetchEvent, updateEvent, loading, fetchEventArguments } = useEvent();
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [argumentsLoaded, setArgumentsLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadEvent = async () => {
      await fetchEvent(identifier);
    };

    loadEvent();
  }, [identifier]);

  useEffect(() => {
    const updateStatus = async () => {
      if (event && event.status !== 'questions_completed' && !statusUpdated) {
        await updateEvent(identifier, { status: 'questions_completed' });
        setStatusUpdated(true);
      }
    };

    updateStatus();
  }, [event, statusUpdated]);

  useEffect(() => {
    const loadArguments = async () => {
      if (event && statusUpdated && !argumentsLoaded) {
        await fetchEventArguments(identifier);
        setArgumentsLoaded(true);
      }
    };

    loadArguments();
  }, [event, statusUpdated, argumentsLoaded]);

  const handleContactOrSignUpRedirect = () => {
    navigate(`/login-or-signup/${identifier}`);
  };

  const handleRetry = () => {
    navigate('/new-event');
  };

  const argumentsList = Array.isArray(event?.arguments_user_description)
    ? event.arguments_user_description
    : [];

  if (loading || !event) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      {event.no_legal_arguments ? (
        <Box textAlign="center">
          <Typography variant="h5" gutterBottom>
            Não foram encontrados argumentos legais para sua defesa.
          </Typography>
          <Typography variant="body1" paragraph>
            Infelizmente, após a análise das informações fornecidas, não conseguimos identificar argumentos legais aplicáveis para sua defesa.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleRetry}>
            Tentar novamente
          </Button>
        </Box>
      ) : (
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            🎉 Ótimas notícias! Encontramos argumentos para sua defesa!
          </Typography>
          <Typography variant="body1" paragraph>
            Abaixo estão os argumentos jurídicos que podem ser usados para anular sua multa:
          </Typography>

          <Grid container spacing={3} justifyContent="center">
            {argumentsList.length > 0 ? (
              argumentsList.map((argument, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#FFD700',
                    }}
                  >
                    <Balance fontSize="large" color="primary" sx={{ mr: 2 }} />
                    <Box>
                      <Typography variant="h6" color="primary">
                        {argument}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Typography>Nenhum argumento disponível.</Typography>
            )}
          </Grid>

          <Button
            variant="contained"
            color="primary"
            endIcon={<Send />}
            onClick={handleContactOrSignUpRedirect}
            sx={{ mt: 4 }}
          >
            Ir para petição
          </Button>
        </Box>
      )}

      {/* Seção de Fatos Interessantes */}
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Fatos Interessantes sobre Recursos de Multas no Brasil
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                textAlign: 'center',
                backgroundColor: '#FFD700', // Cor amarela do theme.js
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Centraliza horizontalmente
                justifyContent: 'center', // Centraliza verticalmente
              }}
            >
              <Box sx={{ fontSize: 48, mb: 1 }}> {/* Aumenta o tamanho do ícone */}
                <VerifiedUser color="action" fontSize="inherit" />
              </Box>
              <Typography>
                Enquanto sua multa não é julgada nas três instâncias possíveis, você não precisa <strong>pagar o valor dela</strong>.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                textAlign: 'center',
                backgroundColor: '#FFD700',
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ fontSize: 48, mb: 1 }}>
                <ReportProblem color="action" fontSize="inherit" />
              </Box>
              <Typography>
                Se você recorrer em todas as <strong>3 instâncias</strong> e o processo demorar mais de <strong>1 ano</strong>, você não receberá os pontos na CNH, mesmo que você perca o recurso.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                textAlign: 'center',
                backgroundColor: '#FFD700',
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ fontSize: 48, mb: 1 }}>
                <Gavel color="action" fontSize="inherit" />
              </Box>
              <Typography>
                Utilizar argumentos jurídicos sólidos aumenta em até <strong>70%</strong> as chances de sucesso.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<Send />}
          onClick={handleContactOrSignUpRedirect}
        >
          Ir para petição
        </Button>
      </Box>
    </Box>
  );
};

export default AnalyzeDefenseArguments;
