import React, { useState, useEffect } from 'react';
import { Button, Typography, CircularProgress, Box, LinearProgress, Grid, Fade } from '@mui/material';
import { ArrowBack, Send } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { useEvent } from '../../../context/EventContext';
import api from '../../../api';

const AnswerViolationQuestions = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const { updateEvent } = useEvent(); // Usa o contexto para gerenciar o evento
  const [messages, setMessages] = useState([]);
  const [answerChoices, setAnswerChoices] = useState([]);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [totalQuestionCount, setTotalQuestionCount] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [isAllAnswered, setIsAllAnswered] = useState(false);

  // Função para buscar as mensagens e perguntas do chat
  const fetchMessages = async () => {
    if (!identifier) return;
    setLoaded(false); // Carregamento inicial
    try {
      const response = await api.get(`/chats/${identifier}`);
      const { messages, answer_choices, total_question_count, current_question_number, started } = response.data;

      setMessages([...messages]);
      setAnswerChoices(answer_choices);
      setTotalQuestionCount(total_question_count);
      setCurrentQuestionNumber(current_question_number);
      setIsAllAnswered(current_question_number >= total_question_count);
      setLoaded(true);

      if (!started) {
        startConversation(identifier); // Inicia a conversa se ainda não tiver começado
      }

      // Atualiza o status para 'violation_informed' no evento
      updateEvent(identifier, { status: 'violation_informed' });

    } catch (error) {
      console.error('Erro ao buscar as mensagens:', error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [identifier]);

  // Função para iniciar a conversa
  const startConversation = async (identifier) => {
    try {
      const response = await api.patch(`/chats/${identifier}/start`);
      if (response.data.success) {
        setMessages(response.data.push_messages);
        setAnswerChoices(response.data.event_question_options);
        setTotalQuestionCount(response.data.total_question_count);
        setCurrentQuestionNumber(response.data.current_question_number);
        setIsAllAnswered(response.data.current_question_number === response.data.total_question_count);
      }
    } catch (error) {
      console.error('Erro ao iniciar a conversa:', error);
    }
  };

  // Função para enviar a resposta escolhida
  const handleAnswer = async (choice) => {
    setAnswerChoices([]);
    setLoaded(false);
    try {
      const response = await api.post(`/chats/${identifier}/answer`, { option_id: choice.id });
      console.log("Resposta da API após envio da resposta:", response.data);
  
      if (response.data.success) {
        setMessages(prevMessages => [...prevMessages, ...response.data.push_messages]);
        setAnswerChoices(response.data.event_question_options);
        setTotalQuestionCount(response.data.total_question_count);
        setCurrentQuestionNumber(response.data.current_question_number);
        setIsAllAnswered(response.data.current_question_number >= response.data.total_question_count);
      }
    } catch (error) {
      console.error('Erro ao enviar a resposta:', error);
    }
    setLoaded(true);
  };  

  // Função para concluir o evento e navegar para a próxima etapa
  const concludeEvent = () => {
    navigate(`/analyze-defense-arguments/${identifier}`);
  };

  // Função para voltar à tela anterior
  const goBack = () => {
    navigate(-1); // Volta à tela anterior
  };

  useEffect(() => {
    if (messages.length > 0) {
      document.getElementById('app-message-list');
    }
  }, [messages]);

  useEffect(() => {
    console.log("isAllAnswered:", isAllAnswered);
    console.log("answerChoices:", answerChoices);
  }, [isAllAnswered, answerChoices]);  

  // Cálculo da barra de progresso baseado na pergunta atual e total de perguntas
  const progressValue = totalQuestionCount > 0 ? (currentQuestionNumber / totalQuestionCount) * 100 : 0;

  return (
    <Box id="chat-app" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', bgcolor: 'background.default', color: 'text.primary' }}>
      <Box id="app-container" sx={{ flex: 1, maxWidth: '900px', mx: 'auto', display: 'flex', flexDirection: 'column' }}>
        <Box id="app-header" sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ color: 'primary.main' }}>Perguntas para sua Defesa</Typography>
        </Box>

        {/* Barra de progresso */}
        {currentQuestionNumber > 0 && (
          <Box sx={{ width: '100%', px: 2, mb: 2 }}>
            <LinearProgress variant="determinate" value={progressValue} />
            <Typography variant="caption" display="block" textAlign="center">
              Progresso: {currentQuestionNumber} de {totalQuestionCount}
            </Typography>
          </Box>
        )}

        <Box id="app-message-list" sx={{ flexGrow: 1, p: 2 }}>
        {loaded ? (
  <>
    {/* Exibe a última mensagem ou a mensagem de conclusão */}
    {isAllAnswered && answerChoices.length === 0 ? (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {messages[messages.length - 1]?.content || "Questionário concluído. Clique em Continuar para prosseguir."}
        </Typography>
      </Box>
    ) : (
      <>
        {messages.length > 0 && (
          <Fade in={true} timeout={500}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {messages[messages.length - 1].content}
              </Typography>
            </Box>
          </Fade>
        )}

        {/* Renderiza as opções de resposta se disponíveis */}
        {answerChoices.length > 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {answerChoices.map(choice => (
              <Fade in={true} timeout={500} key={choice.id}>
                <Button variant="outlined" fullWidth onClick={() => handleAnswer(choice)}>
                  {choice.content}
                </Button>
              </Fade>
            ))}
          </Box>
        )}
      </>
    )}

    {/* Botões de Voltar e Continuar */}
    <Grid container justifyContent="center" spacing={2} sx={{ mt: 4 }}>
      <Grid item>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<ArrowBack />}
          onClick={goBack}
        >
          Voltar
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          endIcon={<Send />}
          onClick={concludeEvent}
          disabled={!isAllAnswered}
        >
          Continuar
        </Button>
      </Grid>
    </Grid>
  </>
) : (
  <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
    <CircularProgress color="primary" />
  </Box>
)}

        </Box>
      </Box>
    </Box>
  );
};

export default AnswerViolationQuestions;
