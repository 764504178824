import React, { createContext, useContext, useState, useEffect } from 'react';
import api, { setAuthToken } from '../api'; // Importa o módulo de API

// Cria o contexto de autenticação
const AuthContext = createContext();

// Hook personalizado para usar o contexto
export const useAuth = () => useContext(AuthContext);

// Provedor de contexto
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authToken, setAuthTokenState] = useState(null); // Armazena o token de autenticação
  const [loading, setLoading] = useState(true); // Inicialmente 'true' enquanto carrega
  const [error, setError] = useState(null);

  // Função para login no AuthContext
  const login = async (email, password) => {
    setLoading(true);
    try {
      console.log('Tentando fazer login com:', email, password);

      // Fazendo a chamada à API
      const response = await api.post('/users/login', { email, password });

      // Verifique se a resposta foi recebida corretamente
      console.log('Resposta da API de login:', response);

      const { token, user } = response.data;

      // Armazena o token e os dados do usuário
      localStorage.setItem('authToken', token);
      setAuthTokenState(token);
      setUser(user);
      setError(null);

      // Configura o token de autenticação no Axios
      setAuthToken(token);

      // Log sucesso
      console.log('Login bem-sucedido. Token armazenado:', token);

      // Retorne a resposta para que o `handleLogin` no componente possa usá-la
      return response.data;  // Retorne o objeto `data`, que contém o token e user
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setError(error.response?.data?.message || 'Erro ao fazer login');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Função para cadastro (sign up)
  const signUp = async (email, password) => {
    setLoading(true);
    try {
      const response = await api.post('/users', { user: { email, password } });
      const { token, user } = response.data;
      if (!token || !user) {
        throw new Error('Token ou usuário não retornado');
      }
      localStorage.setItem('authToken', token);
      setAuthTokenState(token);
      setUser(user);
      setAuthToken(token); // Define o token para o axios
      setError(null);
  
      // Adicione este retorno
      return response.data;
    } catch (error) {
      console.error('Erro ao criar a conta:', error);
      setError(error.response?.data?.message || 'Erro ao criar a conta');
      throw error;
    } finally {
      setLoading(false);
    }
  };  

  // Função para logout
  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthTokenState(null);
    setUser(null);
    setAuthToken(null); // Remove o token de autenticação do Axios
    setLoading(false); // Garante que loading seja falso após logout
  };

  // Verifica se o usuário está autenticado ao carregar a aplicação
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthTokenState(token);
      setAuthToken(token);
      setLoading(false); // Finaliza o loading se o token existir
    } else {
      setLoading(false); // Se não houver token, não precisamos fazer mais nada
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, authToken, loading, error, login, signUp, logout }}>
      {loading ? (
        // Mostra um indicador de carregamento enquanto os dados são carregados
        <div>Carregando...</div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
