import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

// Cria uma instância do Axios
const api = axios.create({
  baseURL: API_BASE_URL,
});

// Função para configurar o token de autenticação
export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

export default api;
