import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress, Alert, TextField, Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { ContentCopy, CheckCircle, Refresh, Send } from '@mui/icons-material';
import { useEvent } from '../../../context/EventContext';
import { useAuth } from '../../../context/AuthContext';

const CompletePayment = () => {
  const { identifier } = useParams(); // Pega o identificador do evento
  const navigate = useNavigate();
  const { event, updateEvent, fetchEvent, loading } = useEvent(); // Usa o contexto para buscar e atualizar o evento
  const { authToken, loading: authLoading } = useAuth(); // Usa o contexto de autenticação para garantir que o usuário está logado
  const [paymentStatus, setPaymentStatus] = useState('');
  const [copySuccess, setCopySuccess] = useState('');
  const [hasGeneratedInvoice, setHasGeneratedInvoice] = useState(false); // Novo estado para controlar a geração da fatura

  // Redireciona para login se não estiver autenticado
  useEffect(() => {
    if (!authToken && !authLoading) {
      navigate('/login');
    }
  }, [authToken, authLoading, navigate]);

  // Função para verificar o status do pagamento
  const checkPaymentStatus = async () => {
    try {
      const response = await fetchEvent(identifier, 'check_payment_status');
      if (response.status === 'paid') {
        setPaymentStatus('Pagamento confirmado! Você pode prosseguir.');
      } else {
        setPaymentStatus('Pagamento ainda pendente. Tente novamente mais tarde.');
      }
    } catch (error) {
      setPaymentStatus('Erro ao verificar o status do pagamento. Tente novamente mais tarde.');
      console.error('Erro ao verificar o status do pagamento:', error);
    }
  };

  // Função para copiar o código Pix para o clipboard
  const copyToClipboard = () => {
    if (event?.iugu_qrcode_text) {
      navigator.clipboard.writeText(event.iugu_qrcode_text);
      setCopySuccess('Código Pix copiado com sucesso!');
    }
  };

  // Função para iniciar o carregamento e atualização de status
  useEffect(() => {
    const generateInvoiceAndFetch = async () => {
      try {
        // Busca o evento se ainda não estiver carregado
        let currentEvent = event;
        if (!currentEvent) {
          await fetchEvent(identifier);
          currentEvent = event;
        }

        if (!currentEvent) return; // Se ainda não tiver o evento, sai da função

        // Gera a fatura apenas se o evento ainda não tiver sido registrado como 'account_registered'
        if (currentEvent.status !== 'account_registered' && !hasGeneratedInvoice) {
          await fetchEvent(identifier, 'generate_invoice'); // Gera e busca a fatura
          await fetchEvent(identifier); // Atualiza o estado do evento com os novos dados
          await updateEvent(identifier, { status: 'account_registered' });
          setHasGeneratedInvoice(true); // Evita que a fatura seja gerada novamente
        }
      } catch (error) {
        console.error('Erro ao gerar a fatura ou atualizar o status:', error);
      }
    };

    if (authToken && !loading && !hasGeneratedInvoice) {
      generateInvoiceAndFetch();
    }
  }, [identifier, fetchEvent, updateEvent, authToken, loading, hasGeneratedInvoice]);

  const handleProceed = () => {
    navigate(`/fill-personal-vehicle-info/${identifier}`);
  };

  if (loading || authLoading || !event) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Fatura de Pagamento
      </Typography>
      <Typography variant="body1" paragraph>
        Garanta seu recurso com um pagamento seguro por apenas <strong>R$ 25,00</strong>.
      </Typography>

      <Typography variant="body1" paragraph>
        Resolver sua multa pode economizar centenas de reais em penalidades. Muitos motoristas já economizaram até 80% do valor da multa contestando. Invista em sua defesa por um custo acessível e evite perder pontos na carteira.
      </Typography>

      {event ? (
        <>
          {event.iugu_qrcode && (
            <Box sx={{ my: 4 }}>
              <Typography variant="h5" gutterBottom>
                Pagamento via Pix
              </Typography>
              <Typography variant="body1" gutterBottom>
                Escaneie o QR Code ou copie o código Pix abaixo:
              </Typography>

              <Box sx={{ my: 3 }}>
                <img src={event.iugu_qrcode} alt="QR Code para pagamento via Pix" style={{ maxWidth: '200px' }} />
              </Box>

              <TextField
                fullWidth
                value={event.iugu_qrcode_text || ''}
                InputProps={{ readOnly: true }}
                sx={{ textAlign: 'center' }}
              />

              <Grid container spacing={2} sx={{ my: 2, justifyContent: 'center' }}>
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<ContentCopy />}
                    onClick={copyToClipboard}
                  >
                    Copiar Código Pix
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="warning"
                    startIcon={<Refresh />}
                    onClick={checkPaymentStatus}
                  >
                    Verificar Pagamento
                  </Button>
                </Grid>
              </Grid>

              {copySuccess && <Alert severity="success">{copySuccess}</Alert>}
            </Box>
          )}

          {paymentStatus && (
            <Typography
              variant="body1"
              color={paymentStatus.includes('confirmado') ? 'success.main' : 'warning.main'}
              sx={{ mt: 2 }}
            >
              {paymentStatus}
            </Typography>
          )}

          {paymentStatus.includes('confirmado') && (
            <Box sx={{ my: 4 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleProceed}
                endIcon={<Send />}
                startIcon={<CheckCircle />}
              >
                Prosseguir
              </Button>
            </Box>
          )}
        </>
      ) : (
        <Typography variant="body1" color="error">
          Erro: A fatura não foi gerada corretamente.
        </Typography>
      )}
    </Box>
  );
};

export default CompletePayment;
