import React, { useState } from 'react';
import { Box, Button, Typography, Paper, Container, Grid, Stepper, Step, StepLabel } from '@mui/material';
import { CheckCircle, Gavel, MoneyOff, Speed, Error, LocalPolice, Security } from '@mui/icons-material';
import { useEvent } from '../context/EventContext';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();
  const { createEvent } = useEvent(); 
  const [loading, setLoading] = useState(false); 

  const handleRecorrerAgora = async () => {
    setLoading(true);
    try {
      await createEvent();
      navigate('/define-violation');
    } catch (error) {
      console.error('Erro ao criar evento:', error);
    } finally {
      setLoading(false);
    }
  };

  const steps = [
    { label: 'Informe os dados da multa', icon: <Speed sx={{ fontSize: 30, color: '#FFD700' }} /> },
    { label: 'Inteligência Artificial', icon: <Gavel sx={{ fontSize: 30, color: '#FFD700' }} /> },
    { label: 'Baixe sua petição', icon: <CheckCircle sx={{ fontSize: 30, color: '#FFD700' }} /> },
  ];

  return (
    <Box sx={{ backgroundColor: '#ffffff', minHeight: '100vh' }}>
      <Box
        sx={{
          backgroundColor: '#000000',
          backgroundSize: 'cover',
          color: '#FFD700',
          py: 10,
          textAlign: 'center',
        }}
      >
        <Typography variant="h2" gutterBottom sx={{ color: 'white' }}>
          Recorra sua multa de trânsito
        </Typography>
        <Typography variant="h5" sx={{ mb: 4, color: 'white' }}>
          Em menos de 5 minutos, sem advogado
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleRecorrerAgora}
          disabled={loading}
          sx={{ px: 6, fontWeight: 700 }}
        >
          {loading ? 'Aguarde...' : 'Comece Agora'}
        </Button>
      </Box>

      {/* Como Funciona - Stepper Section */}
      <Container sx={{ py: 6 }}>
        <Typography variant="h4" align="center" gutterBottom color="primary">
          Como Funciona?
        </Typography>
        <Stepper alternativeLabel sx={{ mt: 4 }}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel icon={step.icon}>
                <Typography variant="body1" color="text.primary">
                  {step.label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>

      {/* Por Que Recorrer Section */}
      <Box sx={{ backgroundColor: '#f5f5f5', py: 6 }}>
        <Container>
          <Typography variant="h4" align="center" gutterBottom color="primary">
            Por que Recorrer de Todas as Multas?
          </Typography>
          <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 4, textAlign: 'center', backgroundColor: '#FFF8E1', border: '1px solid #FFD700' }}>
                <LocalPolice sx={{ fontSize: 50, color: '#FFD700', mb: 2 }} />
                <Typography variant="h6" gutterBottom color="primary">
                  Defesa contra Multas Injustas
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Muitas multas são emitidas incorretamente. Contestar pode reduzir pontos na carteira e manter seu histórico de condução limpo.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 4, textAlign: 'center', backgroundColor: '#FFF8E1', border: '1px solid #FFD700' }}>
                <Error sx={{ fontSize: 50, color: '#FFD700', mb: 2 }} />
                <Typography variant="h6" gutterBottom color="primary">
                  Erros e Inconsistências
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Erros nas informações da multa podem invalidar a infração. Recorrer garante uma análise detalhada para contestação.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 4, textAlign: 'center', backgroundColor: '#FFF8E1', border: '1px solid #FFD700' }}>
                <Security sx={{ fontSize: 50, color: '#FFD700', mb: 2 }} />
                <Typography variant="h6" gutterBottom color="primary">
                  Proteção Financeira
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Evite gastos desnecessários. Recorrer de multas pode salvar você de custos altos e preservar sua pontuação.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Por Que Avarin Section */}
      <Container sx={{ py: 6 }}>
        <Typography variant="h4" align="center" gutterBottom color="primary">
          Por que Escolher o Avarin Multas?
        </Typography>
        <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 4, textAlign: 'center', borderColor: '#FFD700', borderWidth: 2, borderStyle: 'solid' }}>
              <MoneyOff sx={{ fontSize: 50, color: '#FFD700', mb: 2 }} />
              <Typography variant="h6" gutterBottom color="primary">
                Economize com Advogados
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Nosso sistema de IA oferece uma alternativa acessível e eficaz para contestar multas, eliminando a necessidade de advogados caros.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 4, textAlign: 'center', borderColor: '#FFD700', borderWidth: 2, borderStyle: 'solid' }}>
              <CheckCircle sx={{ fontSize: 50, color: '#FFD700', mb: 2 }} />
              <Typography variant="h6" gutterBottom color="primary">
                Petição Rápida e Confiável
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Gera uma petição válida juridicamente em minutos, dando a você controle e rapidez para resolver problemas de forma independente.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 4, textAlign: 'center', borderColor: '#FFD700', borderWidth: 2, borderStyle: 'solid' }}>
              <Gavel sx={{ fontSize: 50, color: '#FFD700', mb: 2 }} />
              <Typography variant="h6" gutterBottom color="primary">
                Fácil e Eficaz
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Basta fornecer as informações essenciais e o Avarin Multas cuida de todo o processo, mantendo-o simples e acessível.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {/* Call to Action Section */}
      <Box
        sx={{
          backgroundColor: '#000000',
          color: '#FFD700',
          py: 6,
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Não perca tempo, recorra agora mesmo!
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }}>
          Gera sua petição em minutos, sem complicações.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleRecorrerAgora}
          disabled={loading}
          sx={{ px: 6, fontWeight: 700 }}
        >
          {loading ? 'Aguarde...' : 'Começar Agora'}
        </Button>
      </Box>
    </Box>
  );
}

export default Home;
