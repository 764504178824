import React from 'react';
import { Box, Typography, Container, Grid, IconButton, Link } from '@mui/material';
import { Facebook, Instagram, Twitter, YouTube, VerifiedUser, Lock } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        color: 'primary.main',
        py: 6,
        borderTop: '4px solid black', // Borda superior preta
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          
          {/* Seção de Informações e Segurança */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Segurança e Privacidade
            </Typography>
            <Link href="/privacy-policy" color="inherit" underline="hover">
              Política de Privacidade
            </Link>
            <br />
            <Link href="/terms-of-service" color="inherit" underline="hover">
              Termos de Serviço
            </Link>
            <br />
            <Link href="/secure-payment" color="inherit" underline="hover">
              Pagamento Seguro
            </Link>
          </Grid>

          {/* Seção de Redes Sociais */}
          <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Conecte-se conosco
            </Typography>
            <IconButton href="https://facebook.com" color="inherit" sx={{ '&:hover': { color: 'secondary.main' } }}>
              <Facebook />
            </IconButton>
            <IconButton href="https://instagram.com" color="inherit" sx={{ '&:hover': { color: 'secondary.main' } }}>
              <Instagram />
            </IconButton>
            <IconButton href="https://twitter.com" color="inherit" sx={{ '&:hover': { color: 'secondary.main' } }}>
              <Twitter />
            </IconButton>
            <IconButton href="https://youtube.com" color="inherit" sx={{ '&:hover': { color: 'secondary.main' } }}>
              <YouTube />
            </IconButton>
          </Grid>

          {/* Seção de Suporte e Segurança */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Suporte
            </Typography>
            <Link href="/faq" color="inherit" underline="hover">
              Perguntas Frequentes (FAQ)
            </Link>
            <br />
            <Link href="/support" color="inherit" underline="hover">
              Suporte Técnico
            </Link>
          </Grid>
        </Grid>

        {/* Direitos Autorais e Ícones de Segurança */}
        <Box textAlign="center" sx={{ mt: 4 }}>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Avarin Multas. Todos os direitos reservados.
          </Typography>
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', gap: 1 }}>
            <Lock color="secondary" />
            <VerifiedUser color="secondary" />
            <Typography variant="body2" color="text.secondary">
              Site seguro
            </Typography>
          </Box>
        </Box>
        
        {/* Linha inferior amarela abaixo da borda preta */}
        <Box
          sx={{
            height: '4px',
            backgroundColor: 'secondary.main',
            mt: 2,
          }}
        />
      </Container>
    </Box>
  );
};

export default Footer;
