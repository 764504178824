import React, { createContext, useContext, useState } from 'react';
import api from '../api'; // Importa o módulo de API

const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const [event, setEvent] = useState(null);
  const [eventIdentifier, setEventIdentifier] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Função para criar um novo evento
  const createEvent = async () => {
    setLoading(true);
    try {
      const response = await api.post('/events', {
        event: { status: 'process_started' },
      });
      setEventIdentifier(response.data.event.identifier);
      setEvent(response.data.event);
      setErrorMessage('');
    } catch (error) {
      console.error('Erro ao criar o evento:', error);
      console.error('Detalhes do erro:', error.response?.data);
      setErrorMessage(
        error.response?.data?.message || 'Erro ao criar o evento'
      );
    } finally {
      setLoading(false);
    }
  };

  // Função para atualizar um evento
  const updateEvent = async (identifier, data) => {
    setLoading(true);
    try {
      const response = await api.patch(`/events/${identifier}`, {
        event: data,
      });
      setEvent(response.data.event);
      setErrorMessage('');
    } catch (error) {
      console.error('Erro ao atualizar o evento:', error);
      console.error('Detalhes do erro:', error.response?.data);
      setErrorMessage(
        error.response?.data?.message || 'Erro ao atualizar o evento'
      );
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar um evento
  const fetchEvent = async (identifier, action = '') => {
    setLoading(true);
    try {
      const endpoint = action
        ? `/events/${identifier}/${action}`
        : `/events/${identifier}`;
      const response = await api.get(endpoint);
      if (response.data.event) {
        setEvent(response.data.event);
      } else if (response.data.success && response.data.invoice) {
        // Caso específico para o generate_invoice que retorna o evento em response.data.event
        await fetchEvent(identifier); // Busque o evento atualizado
      } else {
        setEvent(response.data); // Caso o evento seja retornado na raiz
      }
      setErrorMessage('');
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar o evento:', error);
      setErrorMessage(
        error.response?.data?.message || 'Erro ao buscar o evento'
      );
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar os argumentos do evento
  const fetchEventArguments = async (identifier) => {
    setLoading(true);
    try {
      const response = await api.get(`/events/${identifier}/arguments`);
      setEvent((prevEvent) => ({
        ...prevEvent,
        arguments_user_description: response.data.arguments,
        no_legal_arguments: response.data.no_legal_arguments,
      }));
      setErrorMessage('');
    } catch (error) {
      console.error('Erro ao buscar os argumentos do evento:', error);
      setErrorMessage(
        error.response?.data?.message || 'Erro ao buscar os argumentos do evento'
      );
    } finally {
      setLoading(false);
    }
  };

  // Função para associar o evento ao usuário
  const associateEventWithUser = async (identifier) => {
    setLoading(true);
    try {
      await api.post(`/events/${identifier}/assign_to_user`);
      setErrorMessage('');
    } catch (error) {
      console.error('Erro ao associar o evento ao usuário:', error);
      setErrorMessage(
        error.response?.data?.message ||
          'Erro ao associar o evento ao usuário'
      );
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <EventContext.Provider
      value={{
        event,
        eventIdentifier,
        loading,
        errorMessage,
        createEvent,
        updateEvent,
        fetchEvent,
        fetchEventArguments, // Exporta a nova função
        associateEventWithUser,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

// Hook customizado para usar o contexto
export const useEvent = () => {
  return useContext(EventContext);
};
