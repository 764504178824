import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField, Typography, CircularProgress, Box, Autocomplete, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Send } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useEvent } from '../../../context/EventContext'; // Certifique-se do caminho correto
import api from '../../../api';

const DefineViolation = () => {
  const { event, eventIdentifier, updateEvent, loading } = useEvent();
  const [flows, setFlows] = useState([]);
  const [filteredFlowOptions, setFilteredFlowOptions] = useState([]);
  const [selectedFlowId, setSelectedFlowId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

   // Carregar todos os fluxos e atualizar o status do evento
  useEffect(() => {
    const fetchFlows = async () => {
      try {
        const response = await api.get('/flows');
        setFlows(response.data.flows);
      } catch (error) {
        console.error('Erro ao buscar os flows:', error.message);
      }
    };
    fetchFlows();

    // Só atualiza o status do evento se o status ainda não for 'process_started'
    if (event && event.status !== 'process_started') {
      updateEvent(eventIdentifier, { status: 'process_started' });
    }
  }, [event, eventIdentifier, updateEvent]);

  // Função de seleção de flow
  const selectFlow = (flowId) => {
    setSelectedFlowId(flowId);
  };

  // Lógica do formik para lidar com a submissão do código da infração
  const formik = useFormik({
    initialValues: {
      eventCode: '',
    },
    onSubmit: async (values) => {
      if (selectedFlowId) {
        setIsLoading(true);
        try {
          if (eventIdentifier) {
            // Atualiza o evento com o flow_id selecionado
            await updateEvent(eventIdentifier, { flow_id: selectedFlowId });
            navigate(`/answer-violation-questions/${eventIdentifier}`);
          } else {
            console.error('Erro: Identificador do evento não encontrado.');
          }
        } catch (error) {
          console.error('Erro ao atualizar o evento com flow_id:', error);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  // Filtrar os flows baseado no código de infração digitado
  useEffect(() => {
    if (formik.values.eventCode.length === 4) {
      const filtered = flows.filter(flow => flow.law.startsWith(formik.values.eventCode));
      setFilteredFlowOptions(filtered);
      if (filtered.length === 1) setSelectedFlowId(filtered[0].id);
    } else {
      setFilteredFlowOptions([]);
      setSelectedFlowId('');
    }
  }, [formik.values.eventCode, flows]);

  return (
    <Box className="new-event-container" sx={{ py: 4, display: 'flex', justifyContent: 'center', alignItems: 'flex-start', minHeight: '100vh' }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={8}>
          {loading ? (
            <CircularProgress />
          ) : (
            <form onSubmit={formik.handleSubmit} style={{ textAlign: 'center' }}>

              <Typography variant="h6" sx={{ mb: 2 }}>
                Tem o código da infração? Digite aqui:
              </Typography>
              <TextField
                name="eventCode"
                label="Código da infração"
                variant="outlined"
                fullWidth
                value={formik.values.eventCode}
                onChange={formik.handleChange}
                InputProps={{ inputProps: { maxLength: 4 } }}
                sx={{ marginBottom: 2 }}
              />

              {filteredFlowOptions.length === 0 && formik.values.eventCode.length === 4 && (
                <Typography color="error" sx={{ mb: 2 }}>Nenhuma infração encontrada.</Typography>
              )}
              {filteredFlowOptions.length > 0 && (
                <Box>
                  {filteredFlowOptions.map(option => (
                    <Box key={option.id} sx={{ mb: 1 }}>
                      <Card onClick={() => selectFlow(option.id)} sx={{ cursor: 'pointer' }}>
                        <CardContent>{option.description}</CardContent>
                      </Card>
                    </Box>
                  ))}
                </Box>
              )}

              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Não tem o código da infração com você? Pesquise aqui:
              </Typography>

              <Autocomplete
                options={flows}
                getOptionLabel={(option) => option.description}
                onChange={(event, newValue) => selectFlow(newValue?.id || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pesquisar infração"
                    variant="outlined"
                    fullWidth
                  />
                )}
                sx={{ mb: 4 }}
              />

              {/* Botão de submissão */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!selectedFlowId || loading} // Desabilitar botão se o evento ainda estiver sendo criado
                startIcon={isLoading ? <CircularProgress size={20} /> : <Send />}
              >
                {isLoading ? 'Carregando...' : 'Continuar'}
              </Button>
            </form>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DefineViolation;
