import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Paper, CircularProgress, IconButton } from '@mui/material';
import { CheckCircle, Cancel, Payment } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import api from './../../api';

const UserPetitions = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserEvents = async () => {
      try {
        const response = await api.get('/events/user_events');
        setEvents(response.data.events);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar as petições do usuário:', error);
        setLoading(false);
      }
    };

    fetchUserEvents();
  }, []);

  // Mapeamento dos status para as rotas
  const statusToRouteMap = {
    process_started: '/define-violation',
    violation_detected: '/answer-violation-questions',
    questions_completed: '/analyze-defense-arguments',
    account_registered: '/complete-payment',
    payment_confirmed: '/fill-personal-vehicle-info',
    petition_completed: '/download-petition'
  };

  const handleViewDetails = (event) => {
    const route = statusToRouteMap[event.status];
    if (route) {
      navigate(`${route}/${event.identifier}`);
    } else {
      console.warn('Status desconhecido:', event.status);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: '900px', mx: 'auto' }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
        Minhas Petições
      </Typography>

      {events.length === 0 ? (
        <Typography variant="body1">Você não tem petições criadas.</Typography>
      ) : (
        events.map((event) => (
          <Paper
            key={event.identifier}
            sx={{
              p: 2,
              mb: 2,
              backgroundColor: event.status === 'petition_completed' ? 'success.light' : 'background.paper',
              borderLeft: `6px solid ${event.status === 'petition_completed' ? 'green' : 'grey'}`,
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="h6">
                  <strong>ID:</strong> {event.identifier}
                </Typography>
                <Typography variant="body1">
                  <strong>Data:</strong> {new Date(event.created_at).toLocaleDateString()}
                </Typography>
                <Typography variant="body1">
                  <strong>Status:</strong> {event.status === 'petition_completed' ? 'Pronto' : 'Em andamento'}
                </Typography>
                <Typography variant="body1">
                  <strong>Pagamento:</strong>{' '}
                  {event.iugu_status === 'paid' ? (
                    <span style={{ color: 'green' }}>Pago</span>
                  ) : (
                    <span style={{ color: 'red' }}>Não pago</span>
                  )}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center">
                {event.iugu_status === 'paid' ? (
                  <Payment sx={{ color: 'green', mr: 2 }} />
                ) : (
                  <Cancel sx={{ color: 'red', mr: 2 }} />
                )}
                {event.status === 'petition_completed' ? (
                  <CheckCircle sx={{ color: 'green', mr: 2 }} />
                ) : (
                  <Cancel sx={{ color: 'grey', mr: 2 }} />
                )}
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleViewDetails(event)}
                >
                  Ver detalhes
                </IconButton>
              </Box>
            </Box>
          </Paper>
        ))
      )}

      <Button variant="contained" color="secondary" fullWidth onClick={() => navigate('/')}>
        Voltar para a Página Inicial
      </Button>
    </Box>
  );
};

export default UserPetitions;
