import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Login, Add } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { authToken, user, logout } = useAuth();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose();
  };

  return (
    <AppBar
      position="static"
      elevation={0} // Remove sombra
      sx={{
        backgroundColor: 'background.default', // Fundo branco
        borderBottom: '4px solid', // Borda inferior dupla
        borderColor: 'primary.main', // Borda preta
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Sombra leve para separação
      }}
    >
      <Toolbar>
        {/* Logo */}
        <Typography
          variant="h6"
          component={RouterLink}
          to="/"
          sx={{
            flexGrow: 1,
            textDecoration: 'none',
            color: 'primary.main', // Texto preto
            fontWeight: 700,
            '&:hover': {
              color: 'secondary.main', // Muda para amarelo no hover
            },
          }}
        >
          Avarin Multas
        </Typography>

        {/* Menu para telas grandes */}
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button
            component={RouterLink}
            to="/define-violation"
            endIcon={<Add />}
            sx={{
              color: 'primary.main',
              '&:hover': {
                color: 'secondary.main',
              },
            }}
          >
            Nova Petição
          </Button>
        </Box>

        {/* Menu responsivo - Hamburger para telas pequenas */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem component={RouterLink} to="/" onClick={handleMenuClose}>Home</MenuItem>
          </Menu>
        </Box>

        {/* Botões de Login e Logout */}
        {authToken && user ? (
          <>
            <Button
              component={RouterLink}
              to="/user-petitions"
              startIcon={<ReceiptIcon />}
              sx={{
                color: 'primary.main',
                display: { xs: 'none', md: 'inline-flex' },
                '&:hover': {
                  color: 'secondary.main',
                },
              }}
            >
              Meus Pedidos
            </Button>
            <Button
              startIcon={<LogoutIcon />}
              onClick={handleLogout}
              sx={{
                color: 'primary.main',
                display: { xs: 'none', md: 'inline-flex' },
                '&:hover': {
                  color: 'secondary.main',
                },
              }}
            >
              Sair
            </Button>
          </>
        ) : (
          <Button
            component={RouterLink}
            to="/login"
            variant="contained"
            color="secondary"
            endIcon={<Login />}
            sx={{
              ml: 2,
              borderRadius: 50,
              display: { xs: 'none', md: 'inline-flex' },
              '&:hover': {
                backgroundColor: '#FFC107',
                color: 'primary.main',
              },
            }}
          >
            Logar
          </Button>
        )}
      </Toolbar>

      {/* Linha inferior amarela abaixo da borda preta */}
      <Box
        sx={{
          height: '4px',
          backgroundColor: 'secondary.main',
        }}
      />
    </AppBar>
  );
};

export default Navbar;
