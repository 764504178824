import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import DefineViolation from '../components/form/DefineViolation';
import AnswerViolationQuestions from '../components/form/AnswerViolationQuestions';
import AnalyzeDefenseArguments from '../components/form/AnalyzeDefenseArguments';
import LoginOrSignUp from '../components/form/LoginOrSignUp';
import CompletePayment from '../components/form/CompletePayment';
import FillPersonalAndVehicleInfo from '../components/form/FillPersonalAndVehicleInfo';
import DownloadPetition from '../components/form/DownloadPetition';
import UserPetitions from './../components/profile/UserPetitions';
import Login from './../components/profile/Login';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import ProtectedRoute from './ProtectedRoute'; // Importa o componente de rota protegida

const AppRoutes = () => (
  <>
    <Navbar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/define-violation" element={<DefineViolation />} />
      <Route path="/answer-violation-questions/:identifier" element={<AnswerViolationQuestions />} />
      <Route path="/analyze-defense-arguments/:identifier" element={<AnalyzeDefenseArguments />} />
      <Route path="/login-or-signup/:identifier" element={<LoginOrSignUp />} />

      {/* Rotas protegidas */}
      <Route
        path="/user-petitions"
        element={
          <ProtectedRoute>
            <UserPetitions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/complete-payment/:identifier"
        element={
          <ProtectedRoute>
            <CompletePayment />
          </ProtectedRoute>
        }
      />
      <Route
        path="/fill-personal-vehicle-info/:identifier"
        element={
          <ProtectedRoute>
            <FillPersonalAndVehicleInfo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/download-petition/:identifier"
        element={
          <ProtectedRoute>
            <DownloadPetition />
          </ProtectedRoute>
        }
      />

      <Route path="/login" element={<Login />} />
    </Routes>
    <Footer />
  </>
);

export default AppRoutes;